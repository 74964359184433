<template>
  <div class="bbr-exercises--active-exercises">
    <exercises-list-table
      :exercises="exercises"
      :loading="loading"
      :meta="listMeta"
      @loadMore="loadMore"
    />
  </div>
</template>

<script>
import ExercisesListTable from '../components/ExercisesListTable'
import { mapState, mapActions, mapMutations } from 'vuex'
import Event from '@/services/eventBus'

export default {
  name: 'ActiveExercises',

  components: {
    ExercisesListTable,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.exercises.filter,
      exercises: (state) => state.exercises.completed.list,
      listMeta: (state) => state.exercises.completed.listMeta,
    }),
  },

  created() {
    this.initialize()

    Event.$on('exercise-activated', () => {
      this.clearList()
      this.initialize()
    })
  },

  methods: {
    ...mapActions({
      getExercises: 'exercises/getExercises',
    }),

    ...mapMutations({
      setFilter: 'exercises/setFilter',
      clearList: 'exercises/clearList',
    }),

    initialize() {
      this.setFilter({
        search: null,
        completed: true,
        inTutorialLibrary: null,
        hasCode: null,
      })
      this.fetchExercises()
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchExercises(this.listMeta.current_page + 1)
      }
    },

    async fetchExercises(page = 1) {
      if (this.loading) return

      this.loading = true

      let params = { page, ...this.filter }

      await this.getExercises(params)

      this.loading = false
    },
  },
}
</script>
